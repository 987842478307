import * as React from 'react';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import * as s from './AsideCert.module.scss';

export interface AsideCertProps {
  href: string;
  img: string;
  alt: string;
}

const AsideCert = ({ href, img, alt }: AsideCertProps) => (
  <OutboundLink className={s.link} href={href} rel="noreferrer nofollow" target="_blank">
    <img src={img} className={s.img} width={80} height={80} alt={alt} title={alt} />
  </OutboundLink>
);

export default AsideCert;
